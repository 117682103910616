import React from 'react';
import { Box, Card, CardContent, Container, Stack, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { green, red, yellow } from '@mui/material/colors';
import useTheme from '@mui/material/styles/useTheme';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

interface TopupResultCardProps {
  topupStatus: 'PENDING' | 'COMPLETE' | 'INCOMPLETE';
}

const Icon: React.FC<{ topupStatus: 'PENDING' | 'COMPLETE' | 'INCOMPLETE' }> = ({ topupStatus }) => {
  if (topupStatus === 'COMPLETE') {
    return (
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 78,
          height: 78,
          borderRadius: '50%',
          border: `10px solid ${green[100]}`,
          backgroundColor: green[100],
        }}
      >
        <CheckCircleOutlineIcon
          fontSize="large"
          sx={{
            fontSize: 54,
            color: green[500],
          }}
        />
      </Box>
    );
  }
  if (topupStatus === 'INCOMPLETE') {
    return (
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 78,
          height: 78,
          borderRadius: '50%',
          border: `10px solid ${red[100]}`,
          backgroundColor: red[100],
        }}
      >
        <HighlightOffOutlinedIcon
          fontSize="large"
          sx={{
            fontSize: 54,
            color: red[500],
          }}
        />
      </Box>
    );
  }
  // PENDING state with possible animation
  return (
    <Box
      sx={{
        width: '85%',
        display: 'flex',
        alignItems: 'center', // Center vertically
        justifyContent: 'space-between', // Space items evenly
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: {
            sm: 62,
            md: 78,
          },
          height: {
            sm: 62,
            md: 78,
          },
          borderRadius: '50%',
          border: `10px solid ${yellow[100]}`,
          backgroundColor: yellow[100],
        }}
      >
        <AccountBalanceOutlinedIcon
          fontSize="large"
          sx={{
            fontSize: {
              sm: 43,
              md: 54,
            },
            color: yellow[700],
          }}
        />
      </Box>
      <Box sx={{ flexGrow: 1, mx: 2 }}>
        <LinearProgress
          color="success"
          sx={{
            '& .MuiLinearProgress-bar': {
              backgroundColor: yellow[100], // Bar color
            },
            '& .MuiLinearProgress-bar1Determinate': {
              backgroundColor: yellow[100], // For determinate progress bars
            },
            '& .MuiLinearProgress-bar2Indeterminate': {
              backgroundColor: yellow[200], // For indeterminate progress bars
            },
            '& .MuiLinearProgress-dashed .MuiLinearProgress-bar1Determinate': {
              backgroundColor: yellow[100], // For dashed progress bars (if applicable)
            },
            '& .MuiLinearProgress-dashedColorPrimary': {
              backgroundColor: yellow[500],
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: {
            sm: 62,
            md: 78,
          },
          height: {
            sm: 62,
            md: 78,
          },
          borderRadius: '50%',
          border: `10px solid ${yellow[100]}`,
          backgroundColor: yellow[100],
        }}
      >
        <AccountBalanceWalletOutlinedIcon
          fontSize="large"
          sx={{
            fontSize: {
              sm: 43,
              md: 54,
            },
            color: yellow[700],
          }}
        />
      </Box>
    </Box>
  );
};

export const TopupResultCard: React.FC<TopupResultCardProps> = ({ topupStatus }) => {
  const theme = useTheme();

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Stack alignItems="center">
        <Card sx={{ width: '100%' }}>
          <CardContent sx={{ textAlign: 'center' }}>
            <Stack spacing={4} alignItems="center">
              <Icon topupStatus={topupStatus} />
              <Stack spacing={2} alignItems="center">
                <Typography sx={{ fontSize: 'large' }}>
                  {topupStatus === 'COMPLETE'
                    ? 'เติมเครดิตสำเร็จ'
                    : topupStatus === 'INCOMPLETE'
                      ? 'เติมเครดิตไม่สำเร็จ'
                      : 'กำลังดำเนินการ...'}
                </Typography>
                <Typography variant="caption">
                  {topupStatus === 'COMPLETE'
                    ? 'เครดิตของคุณถูกเพิ่มไปยังกระเป๋าเรียบร้อย'
                    : topupStatus === 'INCOMPLETE'
                      ? 'มีข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่'
                      : 'เมื่อยืนยันการชำระเงินเรียบร้อย คุณจะกลับสู่หน้าหลักอัตโนมัติ'}
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
};
