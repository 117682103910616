import React from 'react';

import { DarkMode, LightMode, Logout, Person, SettingsBrightness, Smartphone, LockReset } from '@mui/icons-material';
import {
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import type { ButtonProps } from '@mui/material';
import { AppContext } from '../../contexts/app_context';
import { createScopedI18n, i18n } from '../../i18n/i18n';
import { APP_VERSION } from '../../config';

const typeI18n = createScopedI18n('graphql.types');

export type UserInfoMenuButtonProps = ButtonProps;

export const UserInfoMenuButton = React.forwardRef(
  (
    { sx, ...props }: UserInfoMenuButtonProps,
    ref: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null | undefined,
  ) => {
    const { contextUser, contextThemeMode, setContextThemeMode } = React.useContext(AppContext);

    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null);
    const menuOpenHandler = (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchor(event.currentTarget);
    };
    const menuCloseHandler = () => setMenuAnchor(null);

    const themeModeChangeHandler = (_event: unknown, value: 'light' | 'system' | 'dark') => {
      if (value !== contextThemeMode) {
        setContextThemeMode(value);
      }
    };

    return (
      <>
        <Button
          ref={ref}
          color="inherit"
          size="small"
          onClick={menuOpenHandler}
          sx={{ justifyContent: 'flex-end', paddingY: '4px', minHeight: 48, textTransform: 'none', ...sx }}
          {...props}
        >
          {contextUser ? (
            <Stack width="100%" alignItems="flex-end" textAlign="end">
              <Stack width="100%">
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  noWrap
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {contextUser.username}
                </Typography>
              </Stack>

              <Stack width="100%">
                <Typography variant="caption" noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {`v${APP_VERSION}`}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            i18n.t('general.empty')
          )}
        </Button>

        <Menu open={!!menuAnchor} anchorEl={menuAnchor} onClose={menuCloseHandler}>
          <MenuList>
            <MenuItem component={Link} to="/profile" onClick={menuCloseHandler}>
              <ListItemIcon>
                <Person fontSize="small" />
              </ListItemIcon>
              {typeI18n('profile')}
            </MenuItem>
            <MenuItem component={Link} to="/profile/change_password" onClick={menuCloseHandler}>
              <ListItemIcon>
                <LockReset fontSize="small" />
              </ListItemIcon>
              {typeI18n('change_password')}
            </MenuItem>
            <MenuItem component={Link} to="/change_phone_number" onClick={menuCloseHandler}>
              <ListItemIcon>
                <Smartphone fontSize="small" />
              </ListItemIcon>
              {typeI18n('change_phone_number')}
            </MenuItem>

            <Divider />

            <MenuItem disableRipple sx={{ '&:hover': { cursor: 'default', background: 'none' } }}>
              <ToggleButtonGroup
                exclusive
                fullWidth
                value={contextThemeMode ?? 'system'}
                onChange={themeModeChangeHandler}
              >
                <ToggleButton value="light">
                  <LightMode />
                </ToggleButton>
                <ToggleButton value="system">
                  <SettingsBrightness />
                </ToggleButton>
                <ToggleButton value="dark">
                  <DarkMode />
                </ToggleButton>
              </ToggleButtonGroup>
            </MenuItem>

            <Divider />

            <MenuItem component={Link} to="/sign_out" onClick={menuCloseHandler}>
              <ListItemIcon>
                <Logout fontSize="small" color="error" />
              </ListItemIcon>
              {typeI18n('sign_out')}
            </MenuItem>
          </MenuList>
        </Menu>
      </>
    );
  },
);
