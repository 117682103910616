import React from 'react';

import { ExpandMore } from '@mui/icons-material';
import { Collapse, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { map } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { formatDate } from '../../../utils/libs';
import { getJobWithJobHiringsGql, JobHiringsCard2 } from './job_hirings_card_2';

import type { JobType } from './restaurant_date_job_hirings_list';

export type DateJobHiringGroupProps = {
  date: string;
  jobs: JobType[];
};

const DateJobHiringGroup = ({ date, jobs }: DateJobHiringGroupProps) => {
  const [expand, setExpand] = React.useState(true);

  const client = useApolloClient();
  React.useLayoutEffect(() => {
    map(jobs, (job) => {
      client.writeQuery({
        query: getJobWithJobHiringsGql,
        variables: { jobId: job.id },
        data: { job },
      });
    });
  }, [jobs]);

  return (
    <Stack gap={0.5}>
      <ListItemButton onClick={() => setExpand(!expand)}>
        <ListItemText
          primary={
            <Typography
              variant="h6"
              flex={1}
              sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}
            >
              {formatDate(date)}
            </Typography>
          }
        />
        <ExpandMore sx={{ transform: expand ? 'rotate(180deg)' : 'rotate(360deg)', transition: '0.2s ease' }} />
      </ListItemButton>

      <Collapse in={expand}>
        <Stack gap={0.5}>
          {map(jobs, (job) => (
            <JobHiringsCard2 key={job.id} jobId={job.id} />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default DateJobHiringGroup;
