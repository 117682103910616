import React from 'react';

import { ArrowDropDown, Search, Store } from '@mui/icons-material';
import type { ButtonProps } from '@mui/material';
import {
  Avatar,
  Button,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { includes, isEmpty, map } from 'lodash';
import type { RestaurantType } from '../../contexts/app_context';
import { AppContext } from '../../contexts/app_context';
import { createScopedI18n, i18n } from '../../i18n/i18n';

const restaurantSelectorI18n = createScopedI18n('components.restaurant_selector');

export type RestaurantSelectorProps = ButtonProps;

const RestaurantJobHiringSelector = React.forwardRef(
  (
    { sx, ...props }: RestaurantSelectorProps,
    ref: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null | undefined,
  ) => {
    const { contextJobHiringRestaurants, contextJobHiringCurrentRestaurant, setContextJobHiringCurrentRestaurant } =
      React.useContext(AppContext);

    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null);
    const menuOpenHandler = (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchor(event.currentTarget);
    };
    const menuCloseHandler = () => setMenuAnchor(null);

    const selectRestaurantHandlerHigherOrder = (restaurant: RestaurantType) => async () => {
      menuCloseHandler();
      setContextJobHiringCurrentRestaurant(restaurant);
    };

    const [filterInput, setFilterInput] = React.useState('');
    const filteredRestaurants = React.useMemo(() => {
      // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
      const _filteredRestaurants = contextJobHiringRestaurants || [];
      if (filterInput === '') return _filteredRestaurants;
      return _filteredRestaurants.filter(
        (res) => includes(res.name, filterInput) || includes(res.branchName, filterInput),
      );
    }, [contextJobHiringRestaurants, filterInput]);

    return (
      <>
        <Button
          ref={ref}
          color="inherit"
          size="small"
          onClick={menuOpenHandler}
          sx={{ minHeight: 48, textTransform: 'none', textAlign: 'inherit', ...sx }}
          {...props}
        >
          <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
            <Avatar
              src={contextJobHiringCurrentRestaurant?.frontPhotoUri}
              sx={{ display: { xs: 'none', sm: 'inherit' }, marginRight: '4px' }}
            >
              <Store />
            </Avatar>

            <Stack width={{ xs: 'calc(100% - 20px)', sm: 'calc(100% - 64px)' }} paddingLeft={{ sm: '4px' }}>
              {contextJobHiringCurrentRestaurant ? (
                <>
                  <Typography noWrap display="inline-block" variant="caption">
                    {contextJobHiringCurrentRestaurant.name}
                  </Typography>
                  <Typography noWrap display="inline-block" variant="subtitle2">
                    {contextJobHiringCurrentRestaurant.branchName}
                  </Typography>
                </>
              ) : (
                <Typography variant="subtitle2" sx={{ opacity: 0.6 }}>
                  {restaurantSelectorI18n('select_restaurant')}
                </Typography>
              )}
            </Stack>

            <Stack width="20px">
              <ArrowDropDown
                fontSize="small"
                sx={{ transform: menuAnchor ? 'rotate(180deg)' : 'rotate(360deg)', transition: '0.2s ease' }}
              />
            </Stack>
          </Stack>
        </Button>

        <Menu
          open={!!menuAnchor}
          anchorEl={menuAnchor}
          onClose={menuCloseHandler}
          slotProps={{ paper: { sx: { width: 330 } } }}
        >
          <MenuList disablePadding>
            <MenuItem>
              <TextField
                fullWidth
                autoFocus
                variant="standard"
                placeholder={restaurantSelectorI18n('filter_restaurant', { joinOutput: true })}
                value={filterInput}
                onKeyDown={(event) => event.stopPropagation()}
                onChange={(event) => setFilterInput(event.target.value)}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </MenuItem>
            {isEmpty(filteredRestaurants) ? (
              <MenuItem divider onClick={menuCloseHandler} sx={{ gap: 1, height: 48 }}>
                <ListItemIcon sx={{ width: 40, justifyContent: 'center' }}>
                  <Store />
                </ListItemIcon>
                {i18n.t('general.empty')}
              </MenuItem>
            ) : (
              <div>
                {map(filteredRestaurants, (restaurant) => (
                  <MenuItem key={restaurant.id} divider onClick={selectRestaurantHandlerHigherOrder(restaurant)}>
                    <Avatar src={restaurant.frontPhotoUri} sx={{ marginRight: '4px' }}>
                      <Store />
                    </Avatar>

                    <Stack width="calc(100% - 44px)" paddingLeft="4px">
                      <Typography noWrap display="inline-block" variant="caption">
                        {restaurant.name}
                      </Typography>
                      <Typography noWrap display="inline-block" variant="subtitle2">
                        {restaurant.branchName}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </div>
            )}
          </MenuList>
        </Menu>
      </>
    );
  },
);

export default RestaurantJobHiringSelector;
