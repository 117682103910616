import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { filter, map, reduce } from 'lodash';
import { ContainerFlexHeight, LoadingSpinner, useTrackPageView } from '../../../components';
import { AppContext } from '../../../contexts/app_context';
import RestaurantDateJobHiringsList from './restaurant_date_job_hirings_list';

import type { RestaurantType } from '../../../contexts/app_context';
import type { JobType } from './restaurant_date_job_hirings_list';

const getPendingJobHiringJobsGql = gql(`
  query getPendingJobHiringJobs {
    pendingJobHiringJobs {
      id
      status
      jobType
      scheduleDate
      jobStartAt
      jobEndAt
      restaurant {
        id
        name
        branchName
        frontPhotoUri
      }
      jobHirings {
        id
        status
        startOperationTime
        endOperationTime
        breakDurationMinute
        student {
          id
          avatar
          firstName
          lastName
          nickName
        }
        studentReview {
          id
        }
      }
    }
  }
`);

const JobHiringsPage = () => {
  const restaurantIdLaneRef = React.useRef<{ [id: string]: HTMLDivElement | null }>({});

  useTrackPageView('JobHiringListPage');

  const { contextJobHiringCurrentRestaurant, setContextJobHiringRestaurants } = React.useContext(AppContext);

  const { data, loading } = useQuery(getPendingJobHiringJobsGql, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  // TODO: Optimize rendering tax when change contextCurrentRestaurant
  const pendingJobs = React.useMemo(
    () => filter(data?.pendingJobHiringJobs, (job) => dayjs(job.scheduleDate).isBefore(dayjs().endOf('day'))),
    [data?.pendingJobHiringJobs],
  );

  const restaurantGroupJobs = React.useMemo(
    () =>
      reduce<
        JobType & { restaurant: RestaurantType },
        { [restaurantId: RestaurantType['id']]: { restaurant: RestaurantType; jobs: JobType[] } }
      >(
        pendingJobs,
        (prev, job) => {
          const { restaurant } = job;
          const restaurantJobs = prev[restaurant.id] ?? { restaurant, jobs: [] };
          restaurantJobs.jobs.push(job);

          return { ...prev, [restaurant.id]: restaurantJobs };
        },
        {},
      ),
    [pendingJobs],
  );

  React.useEffect(() => {
    const restaurants = map(restaurantGroupJobs, ({ restaurant }) => restaurant);
    setContextJobHiringRestaurants(restaurants);
  }, [restaurantGroupJobs]);

  React.useEffect(() => {
    if (contextJobHiringCurrentRestaurant?.id) {
      const laneRef = restaurantIdLaneRef.current[contextJobHiringCurrentRestaurant.id];
      if (laneRef) {
        laneRef.scrollIntoView({ behavior: 'smooth', inline: 'start' });
        laneRef.animate({ opacity: [1, 0.25, 1, 0.25, 1] }, 1000);
      }
    }
  }, [contextJobHiringCurrentRestaurant?.id]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <ContainerFlexHeight maxWidth={false} disableGutters sx={{ display: 'flex' }}>
      <Stack
        flexDirection="row"
        flex={1}
        sx={{
          overflowX: 'scroll',
          scrollSnapType: { xs: 'x mandatory', sm: 'inherit' },
          scrollPadding: '16px',
          paddingLeft: { xs: '48px', sm: '0' },
          paddingRight: { xs: '48px', sm: '0' },
        }}
      >
        {map(restaurantGroupJobs, ({ jobs, restaurant }, restaurantId) => (
          <RestaurantDateJobHiringsList
            key={restaurantId}
            ref={(ref) => {
              restaurantIdLaneRef.current[restaurantId] = ref;
            }}
            jobs={jobs}
            restaurant={restaurant}
            outerStackProps={{
              width: 330,
              margin: 0.5,
              sx: { scrollSnapAlign: 'center', scrollSnapStop: 'always' },
            }}
          />
        ))}
      </Stack>
    </ContainerFlexHeight>
  );
};

export default JobHiringsPage;
